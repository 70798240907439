import React from "react";
import BukharaGuideData from "../../bukharaGuide.json";
import { StyledBukharaGuide, BukharaGuideList } from "./style";
import { BukharaGuideCard } from "./BukharaGuideCard";
import styled from "styled-components";
import { IValue } from "../../types";

export const BukharaGuide = () => {
  const title = "BUKHARA GUIDE";
  return (
    <StyledBukharaGuide>
      <BukharaGuideTitle value={title} />
      <BukharaGuideList>
        {BukharaGuideData.map(({ ident }) => (
          <BukharaGuideCard ident={ident} />
        ))}
      </BukharaGuideList>
    </StyledBukharaGuide>
  );
};

export const BukharaGuideTitle = ({ value }: IValue) => {
  return <StyledBukharaGuideTitle>{value}</StyledBukharaGuideTitle>;
};

export const StyledBukharaGuideTitle = styled.h2`
  font-size: 22px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 35px;
  text-transform: uppercase;
  border-radius: 3px;
  box-shadow: rgba(179, 212, 252, 0.72) 0px 5px 10px 2px;

  @media (max-width: 768px) {
    max-width: 265px;
    margin: 35px auto;
  }
`;
