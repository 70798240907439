import React from "react";
import articleData from "../article.json";
import styled from "styled-components";
import {  useParams } from "react-router-dom";
import { ArticleCard } from "../components/ArticleCard/ArticleCard";
import { AttractionsList } from "../components/AttractionList/AttractionList";

export const Attractions = () => {
  
  const { ident = "sights" } = useParams();
  const detail = articleData.find((articles) => articles.ident === ident);

  const texts = detail?.text.split("\n");

  if (!detail || !texts) {
    return null;
  }

  return (
    <AttractionContainer>
      <ArticleCard {...detail} />
      <AttractionsList/>
    </AttractionContainer>
  );
};


export const AttractionContainer = styled.div`
  max-width: 1200px;
  margin: auto;
`;