import React from "react";
import attractionsData from "../../attractions.json";
import { IAttraction } from "../../types";
import {
  AttractionsListTitle,
  AttractionListContainer,
  StyledAttractionsList,
} from "./style";
import { AttractionItem } from "../AttractionItem/AttractionItem";

export const AttractionsList = () => {
  const attractions: IAttraction[] = attractionsData.map((attraction) => ({
    id: attraction.id,
    name: attraction.name,
    address: attraction.address,
    img: attraction.img,
    text: attraction.text,
    ident: attraction.ident,
  }));

  return (
    <StyledAttractionsList>
      <AttractionsListTitle>Must See Attractions</AttractionsListTitle>
      <AttractionListContainer>
        {attractions.map((attraction) => (
          <AttractionItem {...attraction} />
        ))}
      </AttractionListContainer>
    </StyledAttractionsList>
  );
};
