import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledAttractionItem = styled.li`
  max-width: 155px;
  width: 100%;
  text-align: center;
  box-shadow: rgb(179, 212, 252) 0px 5px 10px 2px;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.5s ease 0s;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const AttractionItemImage = styled.img`
  max-width: 135px;
  border-radius: 5px;
  margin-bottom: 15px;
`;

const AttractionItemTitle = styled.h3`
  font-size: 14px;
  line-height: 100%;
  color: rgb(66 76 85);
  text-align: center;
  text-transform: uppercase;
`;

const AttractionLink = styled(Link)`
  margin: auto;
  text-decoration: none;
`;

export {AttractionLink, AttractionItemTitle, AttractionItemImage, StyledAttractionItem}