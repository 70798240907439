import React from "react";
import BukharaGuideData from "../../bukharaGuide.json";
import {
  StyledBukharaGuide,
  BukharaGuideList,
} from "./style";
import { BukharaGuideCardRus } from "./BukharaGuideCardRus";
import { BukharaGuideTitle } from "./BukharaGuide";

export const BukharaGuideRus = () => {
  const title = "Гайд по Бухаре"
  return (
    <StyledBukharaGuide>
      <BukharaGuideTitle value={title} />
      <BukharaGuideList>
        {BukharaGuideData.map(({ ident }) => (
          <BukharaGuideCardRus ident={ident} />
        ))}
      </BukharaGuideList>
    </StyledBukharaGuide>
  );
};
