import {
  AttractionItemImage,
  AttractionItemTitle,
  StyledAttractionItem,
} from "../../pages/AttractionsRus";
import { IAttraction } from "../../types";
import { AttractionLink } from "./style";

export const AttractionItem = ({ name, img, ident, address }: IAttraction) => {
  return (
    <StyledAttractionItem>
      <AttractionLink className="card-link" to={`/sights/${ident}`}>
        <AttractionItemImage src={img} alt={ident} />
        <AttractionItemTitle>{name}</AttractionItemTitle>
      </AttractionLink>
    </StyledAttractionItem>
  );
};
