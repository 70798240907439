export enum routers {
  MAIN = "/",
  ATTRACTIONS = "bukhara/sights",
  CONTACTS = "contact",
  BUKHARA = "/bukhara",
  TOURS = "/bukhara/tours",
  ATTRACTION_DETAILS = "/sights/:ident",
  HOTELS = "/hotels",
  HOTEL_DETAILS = "/:ident",
  AIRPORT = "/bukhara/airports",
  HISTORY = "/bukhara/history",
  MUSEUMS = "/bukhara/museums",
  RELIGION = "/bukhara/religion",
  
  MAIN_RUS = "/rus",
  ATTRACTIONS_RUS = "/rus/bukhara/sights",
  CONTACTS_RUS = "/rus/contact",
  BUKHARA_RUS = "/rus/bukhara",
  TOURS_RUS = "/rus/bukhara/tours",
  ATTRACTION_DETAILS_RUS = "/rus/sights/:ident",
  HOTELS_RUS = "/rus/hotels",
  HOTEL_DETAILS_RUS = "/rus/:ident",
  AIRPORT_RUS = "/rus/bukhara/airports",
  HISTORY_RUS = "/rus/bukhara/history",
  MUSEUMS_RUS = "/rus/bukhara/museums",
  RELIGION_RUS = "/rus/bukhara/religion"
}