import styled from "styled-components";
import { typography } from "../../ui/typography";

const StyledAttractionsList = styled.div`
  margin: 0 20px;
`;

const AttractionsListTitle = styled.h2`
  ${typography.H2}
  margin: 30px auto;
`;

const AttractionListContainer = styled.ul`
  max-width: 750px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 40px;
  column-gap: 30px;
  margin: auto;

  @media (max-width: 768px) {
    row-gap: 20px;
  }
`;

export {
  StyledAttractionsList,
  AttractionsListTitle,
  AttractionListContainer,
};
